import React, { useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Dashboard as DashboardIcon,
  Add as AddIcon,
  List as ListIcon,
  Notifications as NotificationsIcon,
  PhotoLibrary as PhotoLibraryIcon,
  VpnKey as VpnKeyIcon, // Import icon for Update Password
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';

const AdminBottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/dashboard':
        setValue(0);
        break;
      case '/admin/add-review':
        setValue(1);
        break;
      case '/admin/pending-reviews':
        setValue(2);
        break;
      case '/admin/notifications':
        setValue(3);
        break;
      case '/admin/gallery':
        setValue(4);
        break;
      case '/admin/update-password': // Add path for Update Password
        setValue(5);
        break;
      case '/admin/login':
        setValue(6);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/admin/dashboard');
        break;
      case 1:
        navigate('/admin/add-review');
        break;
      case 2:
        navigate('/admin/pending-reviews');
        break;
      case 3:
        navigate('/admin/notifications');
        break;
      case 4:
        navigate('/admin/gallery');
        break;
      case 5:
        navigate('/admin/update-password'); // Navigate to Update Password
        break;
      case 6:
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
        break;
      default:
        break;
    }
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
        <BottomNavigationAction label="Add Review" icon={<AddIcon />} />
        <BottomNavigationAction label="Pending Reviews" icon={<ListIcon />} />
        <BottomNavigationAction label="Notifications" icon={<NotificationsIcon />} />
        <BottomNavigationAction label="Gallery" icon={<PhotoLibraryIcon />} />
        <BottomNavigationAction label="Update Password" icon={<VpnKeyIcon />} /> {/* New Update Password Button */}
        <BottomNavigationAction label="Logout" icon={<ExitToAppIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default AdminBottomNav;
