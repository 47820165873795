import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { Delete as DeleteIcon, PersonAdd as PersonAddIcon, Edit as EditIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const Root = styled('div')({
  marginTop: 64,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 64,
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: 24,
  width: '100%',
  maxHeight: '70vh', // Set max height for the container
  overflowY: 'auto', // Enable vertical scrolling
  backgroundColor: 'transparent', // Remove white background
});

const TableWrapper = styled('div')({
  width: '90%',
  overflowX: 'auto', // Enable horizontal scrolling
});

const StyledTableRow = styled(TableRow)({
  borderBottom: '1px solid rgba(255, 255, 255, 0.12)', // Slight opacity for row boundary lines
});

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none', // Remove default bottom border
  wordWrap: 'break-word', // Ensure long text wraps within the cell
});

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null); // For managing the user to be edited
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // For modal state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        toast.error('You are not logged in.');
        navigate('/admin/login');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/admin/users`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          toast.error('Failed to load users');
          navigate('/admin/login');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
        navigate('/admin/login');
      }
    };

    fetchUsers();
  }, [navigate]);

  const handleDelete = async (id) => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      toast.error('You are not logged in.');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}/api/admin/users/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setUsers(users.filter(user => user.id !== id));
        toast.success('User deleted successfully!');
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleEdit = (user) => {
    setEditUser(user);
    setIsEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      toast.error('You are not logged in.');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}/api/admin/users/${editUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(editUser),
      });

      if (response.ok) {
        setUsers(users.map(user => user.id === editUser.id ? editUser : user));
        toast.success('User updated successfully!');
        setIsEditModalOpen(false);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="lg">
      <Root>
        <Typography component="h1" variant="h5" color="primary">
          Admin Dashboard
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%', mt: 3 }}>
          <Typography component="h2" variant="h6">
            Users
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
            onClick={() => navigate('/admin/add-user')}
          >
            Add User
          </Button>
        </Box>
        <TableWrapper>
          <StyledTableContainer component={Paper}>
            <Table stickyHeader aria-label="users table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Password</StyledTableCell>
                  <StyledTableCell>Company Name</StyledTableCell>
                  <StyledTableCell align="right">URL</StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <StyledTableRow key={user.id}>
                    <StyledTableCell>{user.id}</StyledTableCell>
                    <StyledTableCell>{user.username}</StyledTableCell>
                    <StyledTableCell>{user.password}</StyledTableCell>
                    <StyledTableCell>{user.company_name}</StyledTableCell>
                    <StyledTableCell align="right">{user.link}</StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton aria-label="edit" onClick={() => handleEdit(user)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" onClick={() => handleDelete(user.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </TableWrapper>

        {/* Edit User Dialog */}
        <Dialog open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please update the user details.
            </DialogContentText>
            <TextField
              margin="dense"
              id="username"
              label="Username"
              name="username"
              fullWidth
              value={editUser?.username || ''}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              name="password"
              fullWidth
              value={editUser?.password || ''}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              id="companyName"
              label="Company Name"
              name="company_name"
              fullWidth
              value={editUser?.company_name || ''}
              onChange={handleEditChange}
            />
            <TextField
              margin="dense"
              id="link"
              label="URL"
              name="link"
              fullWidth
              value={editUser?.link || ''}
              onChange={handleEditChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditModalOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveEdit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Root>
    </Container>
  );
};

export default AdminDashboard;
