import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Typography, Box, List, ListItem, ListItemText, IconButton, Grid, Paper } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        toast.error('You are not logged in.');
        navigate('/admin/login');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/notifications`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setNotifications(data);
        } else {
          toast.error('Failed to load notifications');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchNotifications();
  }, [navigate]);

  const markAsRead = async (id) => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      toast.error('You are not logged in.');
      navigate('/admin/login');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}/api/notifications/${id}/read`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      if (response.ok) {
        setNotifications(notifications.filter(notification => notification.id !== id));
        toast.success('Notification marked as read');
      } else {
        toast.error(`Failed to mark notification as read: ${data.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Notifications
        </Typography>
        <List sx={{ width: '100%' }}>
          {notifications.map((notification) => (
            <Paper key={notification.id} sx={{ marginBottom: 2, padding: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <ListItemText
                    primary={`User ID: ${notification.user_id}`}
                    secondary={`Message: ${notification.message}`}
                  />
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  <IconButton color="primary" onClick={() => markAsRead(notification.id)}>
                    <CheckCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default Notifications;
