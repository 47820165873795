import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Typography, Box, TextField, Button, MenuItem } from '@mui/material';

const AddReview = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [reviewText, setReviewText] = useState('');
  const [responseText, setResponseText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        toast.error('You are not logged in.');
        navigate('/admin/login');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/admin/users`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setUsers(data);
        } else {
          toast.error('Failed to load users');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchUsers();
  }, [navigate]);

  const handleAddReview = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('adminToken');
    if (!token) {
      toast.error('You are not logged in.');
      navigate('/admin/login');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}/api/reviews`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ userId: selectedUser, reviewText, responseText }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Review and response added successfully!');
        setSelectedUser('');
        setReviewText('');
        setResponseText('');
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Add Review
        </Typography>
        <Box component="form" onSubmit={handleAddReview} sx={{ mt: 3 }}>
          <TextField
            select
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="user"
            label="Select User"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="reviewText"
            label="Review Text"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="responseText"
            label="Response Text"
            value={responseText}
            onChange={(e) => setResponseText(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Review
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddReview;
