import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, TextField, Button, Paper, Modal, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction } from '@mui/material';
import { toast } from 'react-toastify';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PendingReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingReview, setEditingReview] = useState(null);
  const [responseText, setResponseText] = useState('');

  useEffect(() => {
    const fetchReviews = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('You are not logged in.');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/reviews`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setReviews(data);
        } else {
          toast.error('Failed to load reviews');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchReviews();
  }, []);

  const handleEdit = (review) => {
    setEditingReview(review);
    setResponseText(review.response_text);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingReview(null);
    setResponseText('');
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in.');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}/api/reviews/${editingReview.id}/response`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ responseText }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Response updated successfully!');
        setReviews(reviews.map((review) =>
          review.id === editingReview.id ? { ...review, response_text: responseText, status: 'edited' } : review
        ));
        handleClose();
      } else {
        toast.error(`Error: ${data.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Pending Reviews
        </Typography>
        <List sx={{ width: '100%', mt: 3 }}>
          {reviews.map((review) => (
            <ListItem key={review.id} alignItems="flex-start">
              <ListItemText
                primary={`Review ID: ${review.id}`}
                secondary={`Review Text: ${review.review_text.substring(0, 50)}...`}
                sx={{ width: '90%' }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(review)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Response
            </Typography>
            {editingReview && (
              <>
                <Typography variant="body1">Review Text: {editingReview.review_text}</Typography>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  margin="normal"
                  label="Edit Response"
                  value={responseText}
                  onChange={(e) => setResponseText(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
                <IconButton
                  edge="end"
                  aria-label="close"
                  onClick={handleClose}
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </Container>
  );
};

export default PendingReviews;
