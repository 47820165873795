import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PendingReviewsAdmin = () => {
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReviews = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        toast.error('You are not logged in.');
        navigate('/admin/login');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/admin/reviews`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setReviews(data);
        } else {
          toast.error('Failed to load reviews');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchReviews();
  }, [navigate]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Pending Reviews
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 3, maxHeight: 440, overflow: 'auto' }}>
          <Table stickyHeader aria-label="pending reviews table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Review Text</TableCell>
                <TableCell>Response Text</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviews.map((review) => (
                <TableRow key={review.id}>
                  <TableCell>{review.id}</TableCell>
                  <TableCell>{review.user_id}</TableCell>
                  <TableCell>{review.review_text}</TableCell>
                  <TableCell>{review.response_text}</TableCell>
                  <TableCell>{review.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default PendingReviewsAdmin;
