import React, { useState, useEffect } from 'react';
import { Button, Container, Typography, Box, IconButton, CardMedia } from '@mui/material';
import { PhotoCamera, Delete as DeleteIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config'; // Import the config file

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const PhotoLibrary = () => {
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('You are not logged in.');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/images`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setImages(data);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message}`);
        }
      } catch (error) {
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchImages();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      toast.error('File size exceeds 5MB. Please select a smaller file.');
      setSelectedFile(null); // Clear selected file if it exceeds the limit
    } else {
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in.');
      return;
    }

    if (!selectedFile) {
      toast.error('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const response = await fetch(`${config.backendUrl}/api/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        toast.success('Image uploaded successfully!');
        setImages([...images, { id: data.id, image_url: data.imageUrl }]);
        setSelectedFile(null);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You are not logged in.');
      return;
    }

    try {
      const response = await fetch(`${config.backendUrl}/api/images/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setImages(images.filter(image => image.id !== id));
        toast.success('Image deleted successfully!');
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ marginTop: 8, mb: 4 }}>
        {/* Upload Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 4,
            borderBottom: '1px solid #ddd',
            pb: 2
          }}
        >
          <Typography component="h1" variant="h5">
            Upload Image
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
            <input
              accept="image/*"
              id="upload-button"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="upload-button">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<PhotoCamera />}
              >
                Select Image
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Selected file: {selectedFile.name}
              </Typography>
            )}
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </Box>
        </Box>

        {/* Image Gallery Section */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Uploaded Images
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            overflowY: 'auto',
            maxHeight: '40vh',
            border: '1px solid #ddd',
            borderRadius: '4px',
            p: 1
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                width: '150px',
                height: '150px',
                overflow: 'hidden',
                borderRadius: '4px',
              }}
            >
              <CardMedia
                component="img"
                height="150"
                image={`${config.backendUrl}${image.image_url}`}
                alt={`Image ${index + 1}`}
                sx={{ objectFit: 'cover' }}
              />
              <IconButton
                onClick={() => handleDelete(image.id)}
                sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default PhotoLibrary;
