import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, Grid, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';

const AdminGallery = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        toast.error('You are not logged in.');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/admin/users`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setUsers(data);
        } else {
          toast.error('Failed to load users');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchUsers();
  }, []);

  const fetchImages = async (userId) => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      toast.error('You are not logged in.');
      return;
    }

    try {
        const response = await fetch(`${config.backendUrl}/api/admin/images?userId=${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      const data = await response.json();
      if (response.ok) {
        setImages(data);
      } else {
        toast.error('Failed to load images');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUser(userId);
    fetchImages(userId);
  };

  return (
    <Box sx={{ width: '100%', padding: 0 }}>
      <Container maxWidth={false} sx={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography component="h1" variant="h5" sx={{ width: '30rem', textAlign: 'start' }}>
            Gallery
          </Typography>
          <FormControl fullWidth sx={{ mt: 2, mb: 4, width: '90%' }}>
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUser}
              onChange={handleUserChange}
              label="Select User"
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ width: '100%', maxHeight: '60vh', overflowY: 'auto' }}>
            <Grid container spacing={2} sx={{ width: '100%', margin: 0 }}>
              {images.map((image) => (
                <Grid item xs={12} sm={6} md={4} key={image.id}>
                  <Paper sx={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    <img src={`${config.backendUrl}${image.image_url}`} alt={image.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminGallery;
