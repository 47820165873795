import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login';
import RequestReview from './components/RequestReview';
import EditMessage from './components/EditMessage';
import PhotoLibrary from './components/PhotoLibrary';
import PendingReviews from './components/PendingReviews';
import PrivateRoute from './components/PrivateRoute';
import BottomNav from './components/Navbar'; // Ensure the correct path here
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import AddUser from './components/AddUser';
import AdminGallery from './components/AdminGallery';
import UpdatePassword from './components/UpdatePassword';
import AdminNavbar from './components/AdminNavbar'; // Ensure the correct path here
import AddReview from './components/AddReview'; // New component
import PendingReviewsAdmin from './components/PendingReviewsAdmin'; // New component
import Notifications from './components/Notifications'; // New component
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();

  const isUserRoute = !location.pathname.startsWith('/admin') && location.pathname !== '/login';
  const isAdminRoute = location.pathname.startsWith('/admin') && location.pathname !== '/admin/login';

  return (
    <div className="App">
      <ToastContainer />
      {isUserRoute && <BottomNav />}
      {isAdminRoute && <AdminNavbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<RequestReview />} />
          <Route path="/request-review" element={<RequestReview />} />
          <Route path="/edit-message" element={<EditMessage />} />
          <Route path="/photo-library" element={<PhotoLibrary />} />
          <Route path="/pending-reviews" element={<PendingReviews />} />
        </Route>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/add-user" element={<AddUser />} />
        <Route path="/admin/gallery" element={<AdminGallery />} />
        <Route path="/admin/update-password" element={<UpdatePassword />} />
        <Route path="/admin/add-review" element={<AddReview />} />   {/* New route */}
        <Route path="/admin/pending-reviews" element={<PendingReviewsAdmin />} />  {/* New route */}
        <Route path="/admin/notifications" element={<Notifications />} />  {/* New route */}
      </Routes>
    </div>
  );
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}
