import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Paper from '@mui/material/Paper';

const BottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case '/request-review':
        setValue(0);
        break;
      case '/edit-message':
        setValue(1);
        break;
      case '/photo-library':
        setValue(2);
        break;
      case '/pending-reviews':
        setValue(3);
        break;
      case '/login':
        setValue(4);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/request-review');
        break;
      case 1:
        navigate('/edit-message');
        break;
      case 2:
        navigate('/photo-library');
        break;
      case 3:
        navigate('/pending-reviews');
        break;
      case 4:
        handleLogout();
        break;
      default:
        break;
    }
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction label="Request Review" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Edit Message" icon={<EditIcon />} />
        <BottomNavigationAction label="Photo Library" icon={<PhotoLibraryIcon />} />
        <BottomNavigationAction label="Pending Reviews" icon={<PendingActionsIcon />} />
        <BottomNavigationAction label="Logout" icon={<ExitToAppIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
