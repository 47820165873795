import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Avatar } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';

const RequestReview = () => {
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [companyName, setCompanyName] = useState('Dummy Company Name'); // Default name
  const [link, setLink] = useState('');

  useEffect(() => {
    const fetchMessageTemplate = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('You are not logged in.');
        return;
      }

      try {
        const response = await fetch(`${config.backendUrl}/api/messages`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setUserMessage(data.message);
          setCompanyName(data.companyName || 'Company Name');
          setLink(data.link || '');
        } else {
          toast.error('Failed to load message template');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
      }
    };

    fetchMessageTemplate();
  }, []);

  const handleSendMessage = async () => {
    if (!userMessage) {
      toast.error('Message template is not loaded.');
      return;
    }

    const dynamicMessage = `Hi ${customerName}, ${userMessage} ${link}`;
    const webhookUrl = "https://services.leadconnectorhq.com/hooks/N3TBlLMvb0ffPxvTwkXm/webhook-trigger/0c72bf78-11d2-4960-a6f7-4402c848e3f3";

    const data = {
      name: customerName,
      phone_number: phoneNumber,
      company_name: companyName,
      message: dynamicMessage
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success('Message sent successfully!');
        setCustomerName('');
        setPhoneNumber('');
      } else {
        const responseData = await response.json();
        toast.error(`Error: ${responseData.message}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar src="/path/to/company-logo.png" alt="Company Logo" sx={{ width: 56, height: 56 }} />
        <Typography component="h1" variant="h5">
          {companyName}
        </Typography>
        <Box component="form" sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="customerName"
            label="Customer Name"
            name="customerName"
            autoComplete="customer-name"
            autoFocus
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="phone-number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSendMessage}
          >
            Send Message
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default RequestReview;
